import styled from "styled-components";

export const Balk = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  width: 96.85px;
  height: 8.9px;
  margin-top: 5px;
`;

export const Titel = styled.h1`
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-size: 2rem;

  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

export const StandpuntenContainer = styled.div`
  background: #f7f2f3;
  padding-top: 4rem;
  padding-bottom: 4rem;
`;

export const PdfContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 20px;
`;

export const Download = styled.a`
  text-decoration: none;
  align-items: center;
  display: block;
  margin-top: 20px;
  text-align: center;
  color: #323232;
`;

export const TextBlock = styled.div`
  display: flex;
  width: 90%;
  max-width: 1100px;
  background-color: white;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
`;

export const TextContainer = styled.div`
  color: black;
  text-align: left;

  padding-left: 8rem;
  padding-top: 4rem;
  padding-right: 8rem;
  padding-bottom: 4rem;

  @media screen and (max-width: 768px) {
    padding-left: 2rem;
    padding-top: 1rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
  }
`;

export const Italic = styled.i`
  font-weight: lighter;
  color: black;
  text-align: left;
`;

export const SubTitel = styled.p`
  font-weight: bold;
  color: black;
  text-align: left;
`;

export const PlainText = styled.p`
  font-weight: lighter;
`;

export const PdfReader = styled.iframe`
  margin: 1rem;
  max-width: 1120px;
  width: 90%;
  height: 1000px;
  justify-content: center;
  align-items: center;
  display: flex;
`;
