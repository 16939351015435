import React from "react";
import balk from "../../images/balk.png";
import {
  Balk,
  StandpuntenContainer,
  Titel,
  Download,
  TextBlock,
  TextContainer,
  Italic,
  SubTitel,
  PlainText,
} from "./ContactElementen";

const ContactSection = () => {
  return (
    <StandpuntenContainer>
      <TextBlock>
        <TextContainer>
          <Titel>E-mail</Titel>
          <Balk src={balk} alt="balk" className="balk"></Balk>
          <PlainText>
            PVDA: <Italic>pvdakrimpenerwaard@gmail.com</Italic> <br></br>
            Sandro Kovacic <Italic>s.kovacic@krimpenerwaard.nl</Italic>{" "}
            <br></br>
            Bart van Ree <Italic>b.vanree@krimpenerwaard.nl</Italic>
          </PlainText>
        </TextContainer>
      </TextBlock>
    </StandpuntenContainer>
  );
};

export default ContactSection;
