import React from "react";
import {
  CardsItem,
  CardsItemLink,
  CardsItemPicWrap,
  CardsItemInfo,
  CardsItemTitle,
  CardsItemText,
  CardsItemSubtext,
  CardsItemDatum,
  CardsItemImg,
} from "./NewsElements";

function CardItem(props) {
  return (
    <>
      <CardsItem>
        <CardsItemLink href={props.path}>
          <CardsItemPicWrap>
            <CardsItemImg alt={props.alt} src={props.src} />
          </CardsItemPicWrap>
          <CardsItemInfo>
            <CardsItemTitle>NIEUWS</CardsItemTitle>
            <CardsItemText>{props.text}</CardsItemText>
            <CardsItemSubtext>{props.subtext}</CardsItemSubtext>
            <CardsItemDatum>{props.datum}</CardsItemDatum>
          </CardsItemInfo>
        </CardsItemLink>
      </CardsItem>
    </>
  );
}

export default CardItem;
