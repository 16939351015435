import React from "react";
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  FooterALink,
} from "./FooterElements";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>OVER ONS</FooterLinkTitle>
              <FooterLink to="/#nieuws">Nieuws</FooterLink>
              <FooterLink to="/mensen">Mensen</FooterLink>
              <FooterLink to="/standpunten">Standpunten</FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>SOCIALE MEDIA</FooterLinkTitle>
              <FooterALink
                href="https://www.instagram.com/pvda_gl_kw/"
                target="_blank"
              >
                Instagram
              </FooterALink>
              <FooterALink
                href="https://www.facebook.com/profile.php?id=100076613912811"
                target="_blank"
              >
                Facebook
              </FooterALink>
            </FooterLinkItems>
          </FooterLinksWrapper>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>PVDA</FooterLinkTitle>
              <FooterALink
                href="https://instagram.com/pvdakrimpenerwaard?utm_medium=copy_link"
                target="_blank"
              >
                Instagram
              </FooterALink>
              <FooterALink
                href="https://www.facebook.com/PvdAKrimpenerwaard"
                target="_blank"
              >
                Facebook
              </FooterALink>
              <FooterALink href="https://twitter.com/pvdakrw" target="_blank">
                Twitter
              </FooterALink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>GROENLINKS</FooterLinkTitle>
              <FooterALink
                href="https://instagram.com/groenlinkskrimpenerwaard?utm_medium=copy_link"
                target="_blank"
              >
                Instagram
              </FooterALink>
              <FooterALink
                href="https://www.facebook.com/profile.php?id=100076218016470"
                target="_blank"
              >
                Facebook
              </FooterALink>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
