import React from "react";
import balk from "../../images/balk.png";
import {
  Balk,
  StandpuntenContainer,
  Titel,
  Download,
  TextBlock,
  TextContainer,
  Italic,
  SubTitel,
  PlainText,
} from "./LedenElementen";

const LedenSection = () => {
  return (
    <StandpuntenContainer>
      <TextBlock>
        <TextContainer>
          <Titel>Lidmaatschap</Titel>
          <Balk src={balk} alt="balk" className="balk"></Balk>
          <SubTitel>PVDA</SubTitel>
          <PlainText>
            Hiermee wordt u lid van de PvdA en daarmee ook gelijk van uw lokale
            PvdA-afdeling en van PvdA/GroenLinks Krimpenerwaard.
          </PlainText>
          <Download href="https://www.pvda.nl/word-lid/">Klik HIER</Download>
          <br></br>
          <br></br>
          <SubTitel>GROENLINKS</SubTitel>
          <PlainText>
            Hiermee wordt u lid van de GroenLinks en daarmee ook gelijk van uw
            lokale GroenLinks-afdeling en van PvdA/GroenLinks Krimpenerwaard.{" "}
            <Download href="https://groenlinks.nl/word-lid">Klik HIER</Download>
          </PlainText>
        </TextContainer>
      </TextBlock>
    </StandpuntenContainer>
  );
};

export default LedenSection;
