import styled from "styled-components";

export const CandidaatTitel = styled.h1`
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-size: 2rem;

  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 1.5rem;
  }
`;
