import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import Footer from "../components/Footer";
import image from "../images/dronekrimpenerwaard.jpeg";
import LedenSection from "../components/LedenSection";

const Lid = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar isOpen={isOpen} toggle={toggle} />
      <HeroSection image={image} title={"LID WORDEN"} big={false} />
      <LedenSection />
      <Footer />
    </>
  );
};

export default Lid;
