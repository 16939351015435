import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages";
import Mensen from "./pages/Mensen";
import Standpunten from "./pages/Standpunten";
import Contact from "./pages/Contact";
import Lid from "./pages/Lid";

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="/mensen" element={<Mensen />} />
      <Route path="/standpunten" element={<Standpunten />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/lid" element={<Lid />} />
    </Routes>
  );
}

export default App;
