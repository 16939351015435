import React from "react";
import CardItem from "./NewsItem";
import img1 from "../../images/img-1.jpeg";
import img2 from "../../images/img-2.jpeg";
import img3 from "../../images/img-3.jpeg";
import balk from "../../images/balk.png";
import aftrap from "../../images/aftrap.jpeg";
import stemwijzer from "../../images/stemwijzer.jpg";
import ov from "../../images/ov.jpeg";
import uitnodiging from "../../images/uitnodiging thema avond-1 3.jpg";
import woningen from "../../images/woningen.jpeg";
import dam from "../../images/dam.jpeg";
import afscheid from "../../images/ceesafscheid.jpeg";
import motie from "../../images/motie.jpeg";
import gemeenteraad from "../../images/gemeenteraad.jpeg";
import wilgen from "../../images/wilgen.jpeg";
import Stikstofstuk from "../../images/stikstofstuk.jpeg";
import principeverzoeken from "../../images/principeverzoeken.png";
import begroting from "../../images/1.png";
import beschouwing from "../../images/beschouwing.png";
import spar from "../../images/spar.jpeg";
import vertrouwenspersoon from "../../images/vertrouwenspersoon.jpeg";
import weiland from "../../images/weiland.jpeg";
import vaag from "../../images/vaag.jpeg";
import festival from "../../images/festival.jpeg";
import gemeentehuissloop from "../../images/gemeentehuissloop.jpeg";
import huurhuis from "../../images/huurhuis.jpg";

import {
  Cards,
  Balk,
  CardsContainer,
  Titel,
  CardsWrapper,
  CardsItems,
} from "./NewsElements";

const NewsSection = () => {
  return (
    <Cards id={"nieuws"}>
      <Titel>Nieuws</Titel>
      <Balk src={balk} alt="balk"></Balk>
      <CardsContainer>
        <CardsWrapper>
          <CardsItems>
            <CardItem
              src={huurhuis}
              text="RAADSVRAGEN - BELANGEN VAN HUURDERS"
              label="Mystery"
              datum="31 mar 2023"
              subtext=""
              alt=""
              path="https://krimpenerwaard.groenlinks.nl/nieuws/raadsvragen-belangen-van-huurders"
            />
            <CardItem
              src={gemeentehuissloop}
              text="PVDA-GROENLINKS: 50% SOCIALE HUUR OP OUDE GEMEENTEHUISLOCATIES"
              label="Mystery"
              datum="22 mar 2023"
              subtext="Dat was de voorwaarde die de linkse fractie koppelde aan steun voor een nieuw gemeentehuis. ‘Wij waren vanaf het begin erg kritisch, tegelijk zijn er duidelijke voordelen. De doorslag was: de gemeente een nieuw duurzaam huis, dan de inwoners ook een nieuw betaalbaar huis’, aldus raadslid Bart van Ree."
              alt=""
              path="https://krimpenerwaard.groenlinks.nl/nieuws/pvda-groenlinks-50-sociale-huur-op-oude-gemeentehuislocaties"
            />
          </CardsItems>
          <CardsItems>
            <CardItem
              src={festival}
              text="Maatregelen voor gehoor- en milieuschade bij evenementen"
              label="Mystery"
              datum="9 feb 2023"
              subtext="De gemeenteraad heeft een nieuw evenementenbeleid vastgesteld. Daarbij stemde het in met zeven voorstellen van PvdA-GroenLinks om gehoor- en milieuschade bij evenementen te beperken. De meeste maatregelen zullen vanaf 2025 gelden."
              alt=""
              path="https://krimpenerwaard.pvda.nl/nieuws/maatregelen-voor-gehoor-en-milieuschade-bij-evenementen/"
            />
            <CardItem
              src={vaag}
              text="TERUGBLIK OP DE RAADSVERGADERING VAN 13 DECEMBER 2022"
              label="Mystery"
              datum="20 dec 2022"
              subtext=""
              alt=""
              path="https://krimpenerwaard.groenlinks.nl/nieuws/terugblik-op-de-raadsvergadering-van-13-december-2022"
            />
            <CardItem
              src={weiland}
              text="HOE GROOT MAG EEN BOERDERIJ WORDEN?"
              label="Mystery"
              datum="8 dec 2022"
              subtext="Naar aanleiding van het artikel in het Algemeen Dagblad: 'Grootste koeienboer Krimpenerwaard wil ondanks stikstofcrisis verder groeien: plan voor 180 extra koeien'"
              alt=""
              path="https://krimpenerwaard.groenlinks.nl/nieuws/hoe-groot-mag-een-boerderij-worden"
            />
          </CardsItems>
          <CardsItems>
            <CardItem
              src={vertrouwenspersoon}
              text="GEMEENTE KRIMPENERWAARD GAAT EEN INTERNE VERTROUWENSPERSOON AANWIJZEN"
              label="Mystery"
              datum="1 dec 2022"
              subtext="Op voorstel van PvdA-GroenLinks heeft de gemeente onder haar medewerkers gepeild of er behoefte is aan een vertrouwenspersoon binnen de organisatie. Uit de peiling blijkt dat ruim een derde dit wil. “Goed dat dit signaal gehoor krijgt”, zegt Bart van Ree, raadslid en indiener van het voorstel."
              alt=""
              path="https://krimpenerwaard.groenlinks.nl/nieuws/gemeente-krimpenerwaard-gaat-een-interne-vertrouwenspersoon-aanwijzen"
            />
            <CardItem
              src={spar}
              text="REACTIE PVDA-GROENLINKS OP VOORGENOMEN SLUITING SPAR AMMERSTOL"
              label="Mystery"
              datum="18 nov 2022"
              subtext=""
              alt=""
              path="https://krimpenerwaard.groenlinks.nl/nieuws/reactie-pvda-groenlinks-op-voorgenomen-sluiting-spar-ammerstol"
            />
            <CardItem
              src={beschouwing}
              text="“ORGANISEER BESTAANSZEKERHEID VOOR NU, STRAKS EN LATER.”"
              label="Mystery"
              datum="10 nov 2022"
              subtext=""
              alt="Stikstofstuk PVDA-GROENLINKS"
              path="https://krimpenerwaard.groenlinks.nl/nieuws/inbreng-algemene-beschouwingen"
            />
          </CardsItems>
          <CardsItems>
            <CardItem
              src={beschouwing}
              text="“ORGANISEER BESTAANSZEKERHEID VOOR NU, STRAKS EN LATER.”"
              label="Mystery"
              datum="10 nov 2022"
              subtext=""
              alt="Stikstofstuk PVDA-GROENLINKS"
              path="https://krimpenerwaard.groenlinks.nl/nieuws/inbreng-algemene-beschouwingen"
            />
            <CardItem
              src={begroting}
              text="Bestaanszekerheid wordt bedreigd, urgentie ontbreekt"
              label="Mystery"
              datum="4 nov 2022"
              subtext="We staan aan de vooravond van de begrotingsbehandeling. Het moment waarop de
              Krimpenerwaardse politiek miljoenen euro’s kan besteden. Dit moment vindt plaats in een tijd
              waarin bestaanszekerheden bedreigd worden door de energiecrisis, wooncrisis en
              klimaatcrisis."
              alt="Reactie begroting 2023 PVDA-GROENLINKS"
              path="https://krimpenerwaard.groenlinks.nl/nieuws/bestaanszekerheid-wordt-bedreigd-urgentie-ontbreekt"
            />
            <CardItem
              src={principeverzoeken}
              text="PvdA-GroenLinks en Leefbaar stellen kritische vragen over beslissing om tijdelijk geen principeverzoeken in behandeling te nemen"
              label="Mystery"
              datum="27 sep 2022"
              subtext=""
              alt="Stikstofstuk PVDA-GROENLINKS"
              path="https://krimpenerwaard.groenlinks.nl/nieuws/pvda-groenlinks-en-leefbaar-stellen-kritische-vragen-over-beslissing-om-tijdelijk-geen"
            />
          </CardsItems>
          <CardsItems>
            <CardItem
              src={Stikstofstuk}
              text="ALS DE VEESTAPEL MORGEN MOET KRIMPEN, WAAROM STAAN WE DAN VANDAAG NOG TOE DAT DEZE GROEIT?"
              label="Mystery"
              datum="12 sep 2022"
              subtext="De zomer van 2022 zal de boeken in gaan als de zomer van de boze boeren. We hebben allemaal de trekkeroptochten, de protestborden en natuurlijk de blauw-wit-rode vlaggen gezien. En dat de boeren boos zijn is goed voor te stellen. De toekomst is onzeker, en er worden over de hoofden van de boeren plannen gemaakt die dan weer gunstig lijken maar vaak catastrofaal."
              alt="Stikstofstuk PVDA-GROENLINKS"
              path="https://krimpenerwaard.groenlinks.nl/nieuws/als-de-veestapel-morgen-moet-krimpen-waarom-staan-we-dan-vandaag-nog-toe-dat-deze-groeit"
            />
            <CardItem
              src={wilgen}
              text="RAADSVRAGEN BOERENACTIE TEGEN ONSCHULDIGE BOMEN"
              label="Mystery"
              datum="27 jun 2022"
              subtext="Aanleiding is het artikel in Het Kontakt van 27 juni: 'Boze boeren Krimpenerwaard zagen knotwilgen af'."
              alt="Motie PVDA-GROENLINKS"
              path="https://krimpenerwaard.groenlinks.nl/nieuws/raadsvragen-over-boerenactie-tegen-onschuldige-bomen"
            />
            <CardItem
              src={gemeenteraad}
              text="Verslag Raadsvergaderingen van 7 en 8 juni 2022"
              label="Mystery"
              datum="8 jun 2022"
              subtext="Lees hier de volledige inbreng van PvdA-GroenLinks over het coalitieakkoord tijdens de raadsvergadering van 7 juni (Sandro Kovačić) en de inbreng van Bart van Ree bijgaand het indienen van de motie  ‘Vroeg signaleren van grensoverschrijdend gedrag’ tijdens de raadsvergadering van 8 juni."
              alt="Motie PVDA-GROENLINKS"
              path="https://krimpenerwaard.pvda.nl/nieuws/verslag-raadsvergaderingen-van-7-en-8-juni-2022/"
            />
          </CardsItems>
        </CardsWrapper>
      </CardsContainer>
    </Cards>
  );
};

export default NewsSection;
