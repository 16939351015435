import styled from "styled-components";

export const Cards = styled.div`
  padding: 4rem;
  background: #f7f2f3;
`;

export const Titel = styled.h1`
  text-align: center;
`;

export const Balk = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 96.85px;
  height: 8.9px;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
`;

export const CardsWrapper = styled.div`
  position: relative;
  margin: 50px 0 45px;
`;

export const CardsItems = styled.ul`
  margin-bottom: 24px;
  color: white;

  @media only screen and (min-width: 1024px) {
    display: flex;
  }

  @media only screen and (max-width: 1024px) {
    margin-bottom: 2rem;
  }
`;

export const CardsItem = styled.li`
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;

  @media only screen and (max-width: 1024px) {
    margin-bottom: 2rem;
  }
`;

export const CardsItemLink = styled.a`
  display: flex;
  flex-flow: column;
  width: 100%;
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  overflow: hidden;
  text-decoration: none;
`;

export const CardsItemPicWrap = styled.figure`
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
`;

export const CardsItemImg = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
`;

export const CardsItemInfo = styled.div`
  padding: 20px 30px 30px;

  background: white;
  position: relative;

  &:hover {
    padding: 20px 30px 30px;
    background: #ede4e5;
    transition: 0.2s all ease-in-out;
  }

  @media only screen and (min-width: 1024px) {
    height: 420px;
  }

  @media only screen and (max-width: 1024px) {
    height: 500px;
  }
`;

export const CardsItemTitle = styled.h5`
  margin-bottom: 30px;
  color: #323232;
  font-size: 12px;
  line-height: 15px;
  font-weight: 750;
`;

export const CardsItemText = styled.h5`
  color: #323232;
  font-size: 18px;
  line-height: 20px;
  font-weight: 750;
`;

export const CardsItemSubtext = styled.h5`
  margin-top: 30px;
  color: #323232;
  font-size: 14px;
  line-height: 22px;
  font-weight: normal;
`;

export const CardsItemDatum = styled.h5`
  margin-top: 30px;
  color: #323232;
  font-size: 9px;
  line-height: 15px;
  font-weight: normal;
  position: absolute;
  bottom: 15px;
`;
