import React from "react";
import balk from "../../images/balk.png";
import {
  Balk,
  StandpuntenContainer,
  Titel,
  Download,
  TextBlock,
  TextContainer,
  Italic,
  SubTitel,
  PlainText,
  PdfReader,
} from "./StandPuntenElementen";

const StandpuntenSection = () => {
  return (
    <StandpuntenContainer>
      <TextBlock>
        <TextContainer>
          <Titel>Verkiezingsprogramma 2022-2026</Titel>
          <Balk src={balk} alt="balk" className="balk"></Balk>
          <Italic>
            {" "}
            Wij wonen in een fantastische gemeente met mooie dorpen, kleine
            steden en een uniek open landschap in de directe nabijheid van
            Rotterdam en Gouda. Er is ruimte voor ontmoeting en ontspanning. Je
            kent je buren, je helpt elkaar als het nodig is, het zijn eenvoudige
            dingen waar we trots op zijn. Je zou alles willen laten zoals het is
            maar dat kan niet. Binnenkort wonen hier meer ouderen dan jongeren.
            Gezinnen worden steeds kleiner, mantelzorg is vanzelfsprekend maar
            als het te lang gaat duren dan wordt het een last. Het belang van
            duurzaamheid is nu veel groter dan een aantal jaren terug.
            Energietransitie, klimaat, natuur, veilige dijken, schaalvergroting,
            bereikbaarheid, het zijn vraagstukken die maken dat de waarden van
            nu onder druk komen te staan. Als we de kleine en grote waarden van
            nu willen behouden dan moeten we keuzes maken. Ons uitgangspunt is
            dat er een optimale combinatie moet zijn tussen bedrijvigheid,
            toerisme en woon- en leefkwaliteit voor onze inwoners. Fijn wonen in
            een duurzame omgeving met ruimte voor ontmoeting en ontspanning. Een
            omgeving waar we respectvol met elkaar omgaan en zeker zijn van
            ondersteuning.
          </Italic>
          <br></br>
          <br></br>
          <br></br>
          <SubTitel>Wonen, woonomgeving</SubTitel>
          <PlainText>
            Prettig wonen, betekent voor iedereen iets anders. Voor
            PvdA-GroenLinks zijn een paar zaken cruciaal. De buurt waar je
            woont, is veilig en fijn, en er zijn genoeg plekken waar je heen
            kunt om anderen te ontmoeten, te sporten of van cultuur te genieten.
            En er zijn genoeg passende woningen met ruimte voor natuurlijk groen
            waar de seizoenen beleefbaar zijn. Voor een veilige en fijne buurt
            is het nodig dat die goed is ingericht, zonder onveilige plekken,
            zodat iedereen er gebruik van kan maken. Tegelijk is van belang dat
            buren en buurtbewoners onderling naar elkaar omkijken. Zodat we
            samen voor elkaar zorgen.
          </PlainText>
          <br></br>
          <br></br>
          <SubTitel>Duurzaamheid</SubTitel>
          <PlainText>
            Alles wat we nu bouwen, ontwikkelen of aanleggen staat er zomaar
            voor twee generaties. Dus het is nodig goed te kiezen. Wij kiezen
            voor een energietransitie waar de lusten en lasten zo eerlijk
            mogelijk worden verdeeld. Voor een groene en veilige woonomgeving.
            Verspilling moeten we voorkomen. We geven, met het bedrijfsleven en
            de maatschappelijke organisaties invulling aan een duurzame economie
            van de toekomst. In deze circulaire economie bestaat geen afval en
            worden grondstoffen steeds opnieuw gebruikt.
          </PlainText>
          <br></br>
          <br></br>
          <SubTitel>Zekerheid voor als het nodig is</SubTitel>
          <PlainText>
            Soms is ondersteuning nodig. Dan moet je makkelijk in elke kern
            ergens terechtkunnen. Laagdrempelig, bij iedereen bekend. We werken
            aan een goede samenwerking tussen gemeente, zorg- en
            welzijnsorganisaties en huisartsen waar je goed wordt geholpen. Zorg
            voor ouderen verdient meer aandacht en houden we in ons eigen
            gebied. Het is niet uit te leggen dat je hier woont en steeds
            eenzamer wordt. Ons doel is dat iedereen kan werken en een inkomen
            heeft. Voor advies en ondersteuning moet je altijd bij de gemeente
            kunnen aankloppen. Bijvoorbeeld als je helaas toch werkloos of
            arbeidsongeschikt wordt of als je gaat scheiden en je met financiële
            verplichtingen zit.
          </PlainText>
          <br></br>
          <br></br>

          <PdfReader
            src="https://drive.google.com/file/d/1K7w6bIqLtPD41a4clM3GKfkorKOAwV7P/preview"
            allow="autoplay"
          ></PdfReader>

          <Download href="https://drive.google.com/uc?export=download&id=1K7w6bIqLtPD41a4clM3GKfkorKOAwV7P">
            Download HIER het hele verkiezingsprogramma
          </Download>
        </TextContainer>
      </TextBlock>
    </StandpuntenContainer>
  );
};

export default StandpuntenSection;
